
.social-container-step {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 0px;
	height: 40px;
	width: 40px;
    
}
.activeStep{
    background-color: #FF4B2B;
    border: 1px solid #FF4B2B ;
    color: #fff !important;
}
.activeLine{
    border-bottom: 2px solid #FF4B2B !important;
}
.SideLine{
    /* width: 112px; */
    height: 47px;
    margin-top:-25px;
    border-bottom: 2px solid #e6dddab9;
    /* margin-left: -5%;
    margin-right: -5%; */
  
}
.stepFormNavTitle{
    padding-top:25px;
}
.stepFormNav{
    /* padding:10%; */
    padding-top:100px;
}
.stepSpan{
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	white-space: nowrap;
}
.stepTitle{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-top:3px;
    white-space: nowrap;
    position:absolute;

    /* align-items: center; */
    align-self: center;
}
@media only screen and (min-device-width: 00px) and (max-device-width: 500px) {
.stepperAdjust{
    display: none !important;
}
.stepperAdjust2{
    left:0 !important;
    /* position: absolute !important; */
    display: block !important;
    padding-top: 15px !important;
    padding-left: 30px !important;
}
.stepTitleBc{
    font-size: 20px !important;
    
}
}