
.paymentHead {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.recentPayment{
  border-color: #F3F4F5;
  background-color:#fff;
  border-radius: 20px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.paymentDashCardMobile {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
  /* min-width: 400px; */
  margin-left: 15px;

  display: none;
  margin-right: 15px;
  margin-bottom: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.paymentDashCard {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  padding-top: 20px;
  padding-bottom: 10px;
  /* min-width: 400px; */
  margin-left: 15px;
  max-width: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  margin-right: 15px;
}
.paymentDashCardCenterMain {
  margin: 0px;
  padding: 0px;
  
}
.paymentDashCardCenter {
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  border-top-left-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1500;
  box-shadow: 0px 15px 15px rgb(242, 242, 242), 0px -15px 15px rgb(241, 241, 241);
}
hr.new {
  border-top: 1px dashed #e1e1e1e0;
}
.paymentText1 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 5px;
  font-weight: 600;
}
.paymentText2 {
  font-family: "Montserrat", sans-serif;
  margin: 5px;
  font-size: 13px;
}
.paymentText3 {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  margin: 5px;
  font-weight: 600;
}
.paymentText4 {
  font-family: "Montserrat", sans-serif;
  margin: 5px;
  font-size: 17px;
}
.paymentImage {
  height: 30px;
  width: 30px;
}
.roundPaymentProfile {
  border-radius: 30px;
  border: solid 1px rgb(181, 181, 181);
  color: rgb(0, 0, 0);
  font-size: 11px;
  padding-top: 6px;
  text-align: center;
  width: 30px;
  height: 30px;
}
.roundPaymentProfileBudge {
  border-radius: 30px;
  color: rgb(0, 0, 0);
  font-size: 11px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  height: 32px;
  padding-left: 16px;
  padding-top: 0px;
  color: #fff;
  background-color: #f46f57;
}
.roundPaymentProfileButton {
  border-radius: 30px;

  color: rgb(0, 0, 0);
  font-size: 15px;
  padding-top: 2px;
  margin-left: 6px;
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #fff;

  text-align: center;
  width: 26px;
  height: 26px;

  font-weight: normal !important;
  font-family: "Montserrat", sans-serif;
}
.roundPaymentProfileButton:hover {
  background-color: #000;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
#chartdiv {
  width: 100%;
  height: 400px;
}
.AppPayment {
  background-color: rgba(179, 166, 166, 0.542);
}
.paymentProfile {
  background-color: #fff;
  margin-top: 100px;
  margin-bottom: 5%;
  z-index: 5;
  border-radius: 25px;
  width: 90%;
  padding-bottom: 1%;
}
.paymentProfileImage {
  z-index: 5;
  border-radius: 50%;
  margin-top: -100px;
  /* border: 1rem solid #fff; */
}
.paymentProfileDetails {
  z-index: 1;
  border-radius: 25px;
  /* background-color:  #fff; */
}
.round {
  border-radius: 25px;
}
.paymentDetails {
  z-index: 1;
  border-radius: 25px;
  /* background-color: #fff; */
}
.payeeName {
  font-family: "Montserrat", sans-serif;
}
.loginStripe {
  background-color: #6495ed;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.loginStripe:hover {
  background-color: #2e86c1;
  text-decoration: none;
  color: #fff;
}
.payOut {
  background-color: #52be80;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.payOut:hover {
  background-color: #138d75;
  text-decoration: none;
  color: #fff;
}
.profileText {
  font-family: "Montserrat", sans-serif;
}
.profileContentText {
  font-family: "Montserrat", sans-serif;
}
.informationListAppNormal {
  margin-top: 50px;
}
.recentPaymentTableMobile{
  display:none;
}
.recentPaymentTableWeb{
  display:block;
}
@media only screen and (min-width: 1100px) and (max-width: 1230px) {
  .stripeBalance{
    display:none;
  }
}
@media only screen and (min-width: 630px) and (max-width: 1100px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .paymentDashCard {
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 20px;
    margin-left: 20px;
  }
  .paymentDashCardMobile {
    display: block;
  }
}

@media only screen and (min-width: 550px) and (max-width: 630px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .informationListAppNormal {
    min-width: 440px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    display: none;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
  }
  .paymentDashCardCenterMain {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 440px) and (max-width: 550px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 340px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 10px;
    margin-left: 10px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 440px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 250px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    margin-left: 10px;
    margin-right: 10px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 10px;
    margin-left: 10px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 350px) {
  .recentPaymentTableMobile{
    display:block;
  }
  .recentPaymentTableWeb{
    display:none;
  }
  .stripeBalance{
    display:none;
  }
  .stripeBalance2{
    display:none;
  }
  .informationListAppNormal {
    min-width: 180px;
    margin-top: 10px;
  }
  .paymentDashCard {
    min-width: 350px;
    margin-left: 5px;
    margin-right: 5px;
    display: none;
  }
  .paymentDashCardCenterMain {
    margin-right: 5px;
    margin-left: 5px;
  }
  .paymentDashCardMobile {
    display: block;
    min-width: 350px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
