@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,800");
body {
  background: #fff;
  /* scrollbar-width: thin; */
}
.modal-backdrop {
  background-color: rgb(0, 0, 0);
  /* backdrop-filter: invert(80%) !important;
  background: rgb(255, 11, 11) !important; */
}
.appResponsive{
  max-width:1440px;
  /* background: #f4f4f498; */
  margin: auto;
}
.profileFillText {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  line-height: 23px;
  color: #7e7d7d;
  padding-left: 7%;
  padding-right: 7%;
}
.profileFillText2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  line-height: 28px;
  color: #ff4949;
}
.progressProfile {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progressProfile:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.progressProfile > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progressProfile .progressProfile-left {
  left: 0;
}

.progressProfile .progressProfile-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 8px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progressProfile .progressProfile-left .progressProfile-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progressProfile .progressProfile-right {
  right: 0;
}

.progressProfile .progressProfile-right .progressProfile-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  
}

.progressProfile.blue .progressProfile-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url("Images/profileFill.jpeg");
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}
.progressProfile.red .progressProfile-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  /* background: #000; */
  font-size: 24px;
  color: #ffffff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-image: url("Images/profileFill.jpeg");
  background-position: center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}

.progressProfile.blue .progressProfile-bar {
  border-color: #dfc4ff;
}

.progressProfile.red .progressProfile-bar {
  border-color: #ff5d5d;
}

.alertApp {
  position: fixed;
  top: 200px;
  right: 0;
  z-index: 500;
}
body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}
.profileFill {
  z-index: 5500;
  padding-top: 5%;

  background-color: rgba(0, 0, 0, 0.386);
}
.profileFill2 {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.bodyAvailable::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bodyAvailable::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.bodyAvailable::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
.bodyAvailable2::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}
.bodyAvailable2::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.bodyAvailable2::-webkit-scrollbar-thumb {
  background: #ff6d53;
}
/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}


/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #aca9a8;
}

.monthlyRentScroll{
  max-height: 300px;
  overflow-y: scroll;
}

.monthlyRentScroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.monthlyRentScroll::-webkit-scrollbar-track {
  background: #c9c8c8;
}
.monthlyRentScroll::-webkit-scrollbar-thumb {
  background: #ff6d53;
}



.AppMain {
  background: hsl(0, 0%, 100%);
}
.AppDark {
  background: #424241;
}
.darkBg {
  background: #424241;
}
.bgPri {
  background-color: #ff4b2b;
}

.bgPriRad {
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff7657);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
}
.bgPriRad2 {
  background: -webkit-linear-gradient(to right, #ff4b2b, #f76046);
  background: linear-gradient(to right, #ff4b2b, #f76046);
}
.textPri {
  color: #ff4b2b !important;
}
.textSuccess {
  color: #2fcb56 !important;
}
.textWarning {
  color: #e2cc26 !important;

}
.textPri2 {
  color: #f86e56;
}
.textPriHover {
  color: #ff4b2b;
}
.textPriHover:hover {
  color: #9c2d1a;
}
.borderPri {
  border: 1px solid #ff4b2b;
}
.borderPri2 {
  border: 2px solid #ff4b2b5e;
}
.borderPri3 {
  border: 2px solid #fc46268b;
}
.bgSec {
  background-color: rgba(224, 220, 220, 0.603);
}
.bgSec2 {
  background-color: rgba(239, 239, 239, 0.603);
}
.textSec {
  color: rgba(224, 220, 220, 0.603);
}
.textSec1 {
  color: rgba(152, 152, 152, 0.865);
}
.textSec2 {
  color: rgba(152, 152, 152, 0.525);
}
.textDark {
  color: rgba(102, 95, 95, 0.734);
}
.borderSec {
  border: 1px solid rgba(224, 220, 220, 0.603);
}
.pointer {
  cursor: pointer;
}
.notAllow {
  cursor: not-allowed;
}
.noPointer {
  cursor: none !important;
}
.axText0 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
}
.axText {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}
.axTextBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 650;
}
.axTextDark {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 20px;
}

.axText1 {
  font-size: 28px;
   font-family: "Montserrat", sans-serif;
}
.loginModal {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  font-family: "Montserrat", sans-serif;
  
  /* height: 100vh; */
  /* margin: 0px 50px; */
}
.borderRightGray {
  border-right: 1px solid rgb(228, 227, 227);
}
.borderLeftGray {
  border-left: 1px solid rgb(239, 239, 239);
}
.borderTopGray {
  border-top: 1px solid rgb(239, 239, 239);
}
.borderBottomGray {
  border-bottom: 1px solid rgb(239, 239, 239);
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 1950;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
p.one {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 850;
  color: #414141;
}
p.two {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 150;
  color: #8e8e8e;
}
h2.one {
  font-family: "Montserrat", sans-serif;
  font-size: 29px;
  font-weight: 1950;
  font-weight: bold;
  line-height: 20px;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 12px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.span2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.spanThin{
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500  !important;
  white-space: nowrap;
}
.span5 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: none;
}
.span6 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  word-wrap: break-word;
}
.span4 {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  white-space: nowrap;
  position: absolute;
  margin-left: -120px;
  margin-top: 25px;
  color: #ff4b2b;
}
.spanBig {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 13px;
}
.span7 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 13px;
}
.span8 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 16px;
}
.spanvalidate {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.sample {
  border-radius: 20px;
  border: solid 1px #e2e2e2;
  background-color: #6772e5;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.buttonPri {
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  cursor: pointer;
}
button.buttonPriHome {
  border-radius: 20px;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}
.buttonSuccess {
  border: solid 1px #2bff87;
  background-color: #2bff87;
  color: #fff;
  cursor: pointer;
}
.textLowerCase{
  text-transform: lowercase !important;
}
.textCapitalizeCase{
  text-transform: capitalize !important;
}
.textNoneCase{
  text-transform: none !important;
}
.textUpperCase{
  text-transform: uppercase !important;
}
.textLeft{
  text-align: left;
}
.textRight{
  text-align: right;
}
button.buttonPriHome:hover {
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
button.buttonPri:hover {
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
.buttonSquarePri {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
  cursor: pointer;
}
.buttonSquareSec {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #a79e9d;
  background-color: #a79e9d;
  color: #fff;
  cursor: pointer;
}
.buttonSquareBlack {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #000000;
  background-color: #000000;
  color: #fff;
  cursor: pointer;
}
.buttonSquareSuccess {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #1aa782;
  background-color: #1aa782;
  color: #fff;
  cursor: pointer;
}
.buttonSquareLinePri {
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;    
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
  cursor: pointer;
}
.buttonSquareLineSec{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  cursor: pointer;
}
.buttonSquareLineBlack{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  border: solid 1px #000000;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
}
.buttonSquareLineSuccess{
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  border: solid 1px #1aa782;
  background-color: transparent;
  color: #1aa782;
  cursor: pointer;
}
.badgeRed {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #ff4b2b;
  background-color: #ff4b2b;
  color: #fff;
}
.badgeRedLine {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #ff4b2b;
  background-color: transparent;
  color: #ff4b2b;
}
.badgeYellow {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #F89212;
  background-color: #F89212;
  color: #fff;
}

.badgeYellowLine {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #F89212;
  background-color: transparent;
  color: #F89212;
}
.badgeGreen {
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: solid 1px #1aa782;
  background-color: #1aa782;
  color: #fff;
}
.sec {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
}
.secDisable {
  border: solid 1px #a79e9d;
  background-color: transparent;
  color: #a79e9d;
  font-weight: bold;
  cursor: not-allowed !important;
}
.sec:hover {
  border: solid 1px #a79e9d;
  background-color: #a79e9d;
  color: #fff;
}
.disable {
  cursor: not-allowed;
}
button.buttonPri:active {
  transform: scale(0.95);
}
button.buttonPri:focus {
  outline: none;
}
.buttonTrans {
  border: solid 0px #fff;
  background: transparent;
  color: #000;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.buttonTransBlack {
  border: solid 1px #000;
  background: transparent;
  color: #000;
  padding: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.buttonDark {
  border: solid 0px #fff;
  background: linear-gradient(to right, #928e8e52, rgba(170, 163, 163, 0.707));
  color: #fff;
}
.rad15 {
  border-radius: 15px;
}
.buttonDark:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px #fff;
  /* color:#616161; */
}
.buttonDark1 {
  border: solid 1px #5f5a5a52;
  color: #5f5a5a52;
  /* background: linear-gradient(to right, #e6e4e4, rgb(235, 231, 231)); */
  background-color: transparent;
}
button.ghost {
  background-color: transparent;
}
button.pri {
}
button.pri:hover {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:active {
  transform: scale(0.95);
}
button.pri2 {
  background-color: #eb6658;
  color: #fff;
}
button.pri2:hover {
  background-color: #fff;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri2:active {
  transform: scale(0.95);
}
.formlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
input:checked {
  border: 1px #9c2d1a solid;
}
input.tag {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.tag2 {
  font-family: "Montserrat", sans-serif;
  background-color: rgb(241, 241, 241);
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
}
input.alert1 {
  border: 1px solid #f76046 !important;
}
input {
  border: none;
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.spanRound {
  border-radius: 25px;
  background-color: #ff4b2b;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
}
.spanRoundDark {
  border-radius: 25px;
  background-color: #aaa9a9;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #fff;
}
.divider {
  /* minor cosmetics */
  display: table;
  font-size: 24px;
  text-align: center;
  width: 75%; /* divider width */
  margin: 30px auto; /* spacing above/below */
}
.divider span {
  display: table-cell;
  position: relative;
}
.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 13px; /* adjust vertical align */
  -moz-background-size: 100% 2px; /* line width */
  background-size: 100% 2px; /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}
.divider span:first-child {
  /* color changes in here */
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(transparent),
    to(rgb(151, 147, 147))
  );
  background-image: -webkit-linear-gradient(
    180deg,
    transparent,
    rgb(151, 147, 147)
  );
  background-image: -moz-linear-gradient(
    180deg,
    transparent,
    rgb(151, 147, 147)
  );
  background-image: -o-linear-gradient(180deg, transparent, rgb(151, 147, 147));
  background-image: linear-gradient(90deg, transparent, rgb(151, 147, 147));
}
.divider span:nth-child(2) {
  color: #ee674f;
  padding: 1px 20px;
  width: auto;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 900;
}
.divider span:last-child {
  /* color changes in here */
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(rgb(155, 153, 153)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(155, 153, 153),
    transparent
  );
  background-image: -moz-linear-gradient(
    180deg,
    rgb(155, 153, 153),
    transparent
  );
  background-image: -o-linear-gradient(180deg, rgb(155, 153, 153), transparent);
  background-image: linear-gradient(90deg, rgb(155, 153, 153), transparent);
}


.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.itemCenter{
  margin: auto;
}
.PDFWeb{
  display: block;
}
.PDFMobile{
  display: none;
}
.dropdown-item {
  display: block;
  width: 100%;
 padding: .3rem .7rem !important;
}
@media only screen and (min-width: 970px) and (max-width: 1230px) {
  button.buttonPriHome {
    font-size: 11px;
    padding: 10px 35px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 850px) {
  button.buttonPriHome {
    font-size: 10px;

    padding: 8px 30px;
  }
  .axText {
    font-size: 20px;
  }
  .axText0 {
    font-size: 22px;
  }
  .axTextDark {
    font-size: 28px;
  }
  .axText1 {
    font-size: 25px;
  }
}
@media only screen and (min-width:0px) and (max-width: 550px) {
  .PDFWeb{
    display: none;
  }
  .PDFMobile{
    display: block;
  }
  button.buttonPriHome {
    font-size: 10px;

    padding: 8px 30px;
  }
  .axText0 {
    font-size: 20px;
  }
  .axText {
    font-size: 20px;
  }
  .axTextDark {
    font-size: 20px;
  }
  .axText1 {
    font-size: 18px;
  }

}


.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*='col-'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row.equal-cols > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; 
}