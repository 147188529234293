
.navTabLink {
  margin: 0px;
  padding: 15px;
  padding-right: 25px;
  padding-left: 25px;
  color: black;
  font-family: "Montserrat", sans-serif;
}
.navTabLink .active {
  color: #ff4b2b;
}
.navTabLink.makeWhite {
  color: #bebebe !important;
}
.navTabLink.makeWhite.active {
  color: #ff4b2b !important;
}
.navTabLink:hover {
  color: #ff4b2b;
}
.viewListingPad{
  box-shadow: 0px 20px 20px rgb(232, 232, 232), 0px -25px 25px rgb(228, 228, 228), 0px 25px 25px rgb(224, 224, 224), 0px 20px 20px rgb(222, 222, 222);
}
#myTab{
  z-index: 1012 !important;
}
.nav-item.makeWhite {
  background-color: #bebebe5d;
}
.searchTab {
  position: absolute;
  margin-top: 22px;
  margin-left: -30px;
}
.tabSearchInput {
  padding-right: 40px !important;
}

.listCheck:indeterminate {
}
input[type="checkbox"]:before {
  border: 7px solid rgb(255, 11, 11);
}
input[type="checkbox"]:after {
  border: 1px solid rgb(255, 11, 11);
}
.addList {
  margin-bottom: -200px;
}
@media only screen and (min-width: 550px) and (max-width: 680px) {
  .addList {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 550px) {
  .addList {
    margin-bottom: 10px;
  }
  .removeUnit {
    display: none;
  }
  .dragDrop {
    width: 300px;
  }
}
.addListingTabMobile{
  display: none;
}
.addListingTabWeb{
  display: flex;
}
@media only screen and (min-width: 350px) and (max-width: 400px) {
  .addListingTabMobile{
    display: block;
  }
  .addListingTabWeb{
    display: none;
  }
  .addList {
    margin-bottom: 10px;
  }
  .dragDrop {
    width: 300px;
  }
  .navTabLink {
    padding-right: 15px;
    padding-left: 15px;
  }
  .searchListContentApp {
    font-size: 9px;
    line-height: 10px;
  }
  .searchListContentApp2 {
    font-size: 9px;
    line-height: 10px;
  }
  .removeUnit {
    display: none;
  }
}
@media only screen and (min-width: 00px) and (max-width: 350px) {
  .addListingTabMobile{
    display: block;
  }
  .addListingTabWeb{
    display: none;
  }
  .addList {
    margin-bottom: 10px;
  }
  .dragDrop {
    width: 250px;
  }
  .removeUnit {
    display: none;
  }
  .navTabLink {
    padding-right: 8px;
    padding-left: 8px;
  }
  .searchListContentApp {
    font-size: 9px;
    line-height: 10px;
  }
  .searchListContentApp2 {
    font-size: 9px;
    line-height: 10px;
  }
}
