.paymentLandlordCard{
     background-color: rgb(255, 255, 255);
     border-radius: 15px;
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
}
.paymentLandlordCardHead {
     font-family: "Montserrat", sans-serif;
     font-size: 15px;
     font-weight: 500;
}
.paymentLandlordCardText {
     font-family: "Montserrat", sans-serif;
     font-size: 20px;
     font-weight: 600;
   }
   .dropDownStyle2{
     font-family: "Montserrat", sans-serif;
     cursor: pointer;
     border-radius: 25px;
     padding:5px 25px;
     background-color: rgb(232, 232, 232);
     font-size: 16px;
     font-weight: 600;

   }
   .custom-select:disabled {
     color: #000000;
     background-color: #ffffff;
 }