.noDataImage{
    height:750px;
    width:750px;
}
.noData1{
    height:250px;
    width:250px;
}
.nodataSection{
    
}
.noDataHead{
    color: #42dc13;
    font-size: 100px;
    font-family: 'Exo 2', sans-serif;
    text-shadow: 5px 5px #e2e9eb;
    white-space: nowrap;
    /* font-family: 'Kanit', sans-serif; */
    /* font-family: 'Titillium Web', sans-serif; */
    /* font-family: 'Ubuntu', sans-serif; */
}
@media only screen and (min-width: 780px) and (max-width: 1050px) {
    .noDataImage {
        height:750px;
        width:750px;
    } 
    .noDataImage {
        height:750px;
        width:650px;
    }
    .noDataHead{
        font-size: 80px;
    }
}
@media only screen and (min-width: 550px) and (max-width: 780px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
    }
}
@media only screen and (min-width: 390px) and (max-width: 550px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
        margin-left: 80px;
    }
}
@media only screen and (min-width: 224px) and (max-width: 390px) {
    .noDataImage {
        height:650px;
        width:250px;
    }
    .noDataImage {
        height:850px;
        width:350px;
    }
    .noDataHead{
        font-size: 70px;
        margin-left: 120px;
    }
}