@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.loginModal {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center;
	flex-direction: column; */
  font-family: "Montserrat", sans-serif;

  margin: 20px 50px;
  padding-top: 150px;
  padding-bottom: 90px;
}
.loginForgot {
  color: rgb(142, 142, 142);
  cursor: pointer;
}
.loginForgot:hover {
  color: rgb(71, 175, 255);
}
.loginModal-mobile {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 530px;
  margin: 10px 30px;
  margin-top: 90px;
  display: none;
}
#container {
  /* margin-top:100px; */
}
.landlordLogin{
  color: rgb(120, 119, 119);
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  text-decoration: none;
  color: #fff;
}
.logo:hover {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  color: #fff;
  text-decoration: none;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
 margin: 20px 0 30px; 
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 12px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.spanvalidate {
  font-size: 12px;
  align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
}
button.pri {
}
button.pri:hover {
  background-color: transparent;
  border-color: #ff4b2b;
  color: #ff4b2b;
}
button.pri:active {
  transform: scale(0.95);
}
.formlogin {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input.tag {
  border: none;

  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  font-family: "Montserrat", sans-serif;
}
input.toggle {
}
.term {
  color: #868b8b;
}
.term:hover {
  color: #ff4b2b;
  cursor: pointer;
}
.term1 {
  color: #14a1f9;
}
.term1:hover {
  color: #ff4b2b;
  cursor: pointer;
}
.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  overflow: hidden;
  width: 868px;
  max-width: 100%;
  min-height: 520px;
}
.container-mobile {
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22); */
  position: relative;
  /* overflow: hidden; */
  width: 868px;
  max-width: 100%;
  /* min-height: 630px; */
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}
.sign-in-container-mobile {
  top: 0;
  width: 100%;
  z-index: 2;
}
.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  padding-top: 30px;
}
.sign-up-container-mobile {
  top: 0;
  width: 100%;
  z-index: 1;
}
.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.passDropHead {
  background-color: #cfd6d6;
}
.passDropContent {
  background-color: #e4e9e9;
}
/* 
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
} */

/* toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a3e4d7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f5cba7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(85px);
  -ms-transform: translateX(85px);
  transform: translateX(85px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on,
.off {
  color: rgb(104, 98, 98);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.iconPassShow {
  padding-top: 26px;
  right: 0;
  padding-right: 60px;
}
.iconPassShowSignUp {
  padding-top: 24px;
  right: 0;
  padding-right: 64px;
}
.iconPassShowSignUp2 {
  padding-top: 24px;
  right: 0;
  padding-right: 64px;
}
.passwordRuleBox{
  z-index: 5000;
  position: absolute;
  transform: translate3d(50px, -24px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}
@media only screen and (min-device-width: 1800px) and (max-device-width: 6400px) {
  .loginModal {
    margin: 20px 50px;
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
@media only screen and (min-device-width: 1300px) and (max-device-width: 1800px) {
  .loginModal {
    margin: 20px 50px;
    padding-top: 150px;
    padding-bottom: 90px;
  }
}
@media only screen and (min-device-width: 980px) and (max-device-width: 1300px) {
  .loginModal {
    margin: 20px 50px;
    margin-top: -10px;
    margin-bottom: -45px;
  }
}
@media only screen and (min-device-width: 790px) and (max-device-width: 980px) {
  .passwordRuleBox{
    position: absolute;
    transform: translate3d(50px, -35px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
  }
  h1.loginHead {
    font-size: 29px;
  }
  .sign-up-container {
    padding-top: 40px;
  }
  .loginModal {
    margin: 20px 50px;
    margin-top: -10px;
    margin-bottom: -45px;
  }
}
@media only screen and (min-device-width: 630px) and (max-device-width: 790px) {
  .passwordRuleBox{
    z-index: 80000 !important;
    position: absolute;
    transform: translate3d(20px, 7px, 0px);
    top: 0px;
    left: 0px;
    /* will-change: transform; */
  }
  .sign-up-container {
    padding-top: 10px;
  }
  .loginModal {
    margin: 20px 50px;

    margin-top: -10px;
    margin-bottom: -45px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 10px;
  }
  .formlogin {
    padding: 0 30px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 360px;
  }
  span {
    font-size: 10px;
    /* line-height: 30px; */
  }
  .switch {
    width: 40px;
    height: 26px;
    margin: top -1px;
    margin-bottom: 0px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    margin-top: -4px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(52px);
    -ms-transform: translateX(52px);
    transform: translateX(52px);
  }
}
@media only screen and (min-device-width: 520px) and (max-device-width: 630px) {
  .passwordRuleBox{
    z-index: 80000 !important;
    position: absolute;
    transform: translate3d(20px, -7px, 0px);
    top: 0px;
    left: 0px;
    /* will-change: transform; */
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  .loginModal {
    margin: 10px 30px;
    margin-top: -5px;
    margin-bottom: -45px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 6px 20px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 340px;
  }
  span {
    font-size: 8px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(41px);
    -ms-transform: translateX(41px);
    transform: translateX(41px);
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 520px) {
  .loginModal-mobile {
    display: block;
    margin: 70px 20px;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 19px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 5px 17px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 7px;
    /* line-height: 30px; */
  }
  .switch {
    width: 100px;
    height: 17px;

  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
  }
}
@media only screen and (min-width: 790px) and (max-width: 890px) {
  .iconPassShow {
    margin-top: -10px;
    right: 0;
    padding-right: 30px;
  }
  .iconPassShowSignUp {
    margin-top: 0px;
    right: 0;
    padding-right: 30px;
  }
  .iconPassShowSignUp2 {
    margin-top: 0px;
    right: 0;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 630px) and (max-width: 790px) {
  .iconPassShow {
    margin-top: -60px;
    right: 0;
    padding-right: 30px;
  }
    .iconPassShowSignUp {
    margin-top: -10px;
    right: 0;
    padding-right: 30px;
  }
  .iconPassShowSignUp2 {
    margin-top: -10px;
    right: 0;
    padding-right: 30px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
    padding-top:90px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 6px 0 !important;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 520px;
  }
  span {
    font-size: 10px;
    /* line-height: 30px; */
  }
  .switch {
    width: 90px;
    height: 26px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(62px);
    -ms-transform: translateX(62px);
    transform: translateX(62px);
  }
}
@media only screen and (min-width: 522px) and (max-width: 629px) {
  .iconPassShow {
    margin-top: -45px;
    right: 0;
    padding-right: 30px;
  }
    .iconPassShowSignUp {
    margin-top: -12px;
    right: 0;
    padding-right: 30px;
  }
  .iconPassShowSignUp2 {
    margin-top: -12px;
    right: 0;
    padding-right: 30px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  .sign-up-container {
    padding-top: 10px;
  }
  a.logo {
    font-size: 20px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 6px 20px;
    font-size: 10px;
  }
  p {
    font-size: 12px;
  }
  .formlogin {
    padding: 0 20px;
    padding-top:70px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 470px;
  }
  span {
    font-size: 8px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    font-size: 7px;
    margin-top:-3px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(61px);
    -ms-transform: translateX(61px);
    transform: translateX(61px);
  }
}
@media only screen and (min-width: 00px) and (max-width: 350px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -30px;
  }
  .iconPassShowSignUp {
    padding-top: 143px;
    top: 0;
    margin-right: -25px;
  }
  .iconPassShowSignUp2 {
    padding-top: -143px;
    top: 1;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 430px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -20px;
  }
  .iconPassShowSignUp {
    padding-top: 143px;
    top: 0;
    margin-right: -25px;
  }
  .iconPassShowSignUp2 {
    padding-top: -217px;
    top: 1;
    margin-right: -25px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 480px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: -10px;
  }
  .iconPassShowSignUp {
    padding-top: 143px;
    top: 0;
    margin-right: 3px;
  }
  .iconPassShowSignUp2 {
    padding-top: -217px;
    top: 1;
    margin-right: 3px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 522px) {
  .iconPassShow {
    padding-top: 213px;
    top: 0;
    margin-right: 20px;
  }
  .iconPassShowSignUp {
    padding-top: 143px;
    top: 0;
    margin-right: 20px;
  }
  .iconPassShowSignUp2 {
    padding-top: -217px;
    top: 1;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 791px) and (max-width: 851px) {
  .iconPassShow {
    padding-top: 25px;
    right: 0;
    padding-right: 60px;
  }
  .iconPassShowSignUp {
    padding-top: 25px;
    right: 0;
    padding-right: 60px;
  }
  .iconPassShowSignUp2 {
    padding-top: 25px;
    right: 0;
    padding-right: 60px;
  }
}
@media only screen and (min-width: 851px) and (max-width: 980px) {
  .iconPassShow {
    padding-top: 20px;
    right: 0;
    padding-right: 60px;
  }
  .iconPassShowSignUp {
    padding-top: 24px;
    right: 0;
    padding-right: 60px;
  }
  .iconPassShowSignUp2 {
    padding-top: 24px;
    right: 0;
    padding-right: 60px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 522px) {
  .passwordRuleBox{
    z-index: 80000 !important;
    position: absolute;
    transform: translate3d(20px, -85px, 0px);
    top: 0px;
    left: 0px;
    /* will-change: transform; */
  }
  .loginModal-mobile{
    margin-bottom: 150px;
  }
  .form-container{
    margin-top: 50px;
   
  }
  .loginModal-mobile {
    display: block;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 25px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 13px;
  }
  button {
    padding: 5px 17px;
    font-size: 10px;
  }
  p {
    font-size: 10px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 17px;
    /* margin-left:-10px; */
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on,
  .off {
    top: 60%;
    left: 50%;
    margin-top:-1px;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(63px);
    -ms-transform: translateX(63px);
    transform: translateX(63px);
  }
}
