.contractTextStyle{
     white-space: pre-line;
     letter-spacing:1px;
     line-height: 25px;
     padding-top:15px;
}
.beingTermInputBcContract{
     padding-top:25px;
     margin-top:10px;
}
.beingTermDateLabelBcContract{
     float: right;
}
.addressForServiceBcApp{
     margin-top:5px;
}
.bcPeriodCheckBoxes{
     margin-top: 10px;
     margin-bottom: 5px;
     margin-left: 100px;
}
.fixedTermInputBc{
     padding-top:25px;
     margin-top: -28px;
}
.tenancyEndReasonDiv{
     margin-left: 70px; 
}
.rentContractInputBC{
      margin-top: -40px;
}
.rentTypeBCContract{
     margin-top: 2px;
}
.rentDayInputBC{
     margin-top: -14px;
     margin-left: -12px;
     max-width: 270px !important;
}
.rentalInfoSentenceBCContract{
     margin-left:10px;
     margin-right:10px;
     min-width:70px;
}
.rentalInfoSentenceBCContract2{
     width:40%;
}
.step2CardContainerBCContract{
     margin-top: -20px;
     margin-bottom: -20px;
     margin-right: 30px;
     margin-left: 30px;
     font-weight: 600;
}
.bcAgreementSubTitle{
     font-size: 17px;
     font-weight: 600;
     font-family: "Montserrat", sans-serif;

}
.bcAgreementSubTitle2{
     font-size: 17px;
     font-weight: 600;
     font-family: "Montserrat", sans-serif;

}
.demoDataBcContract{
     margin-bottom:-25px ;
}
@media only screen and (min-width:1244px) and (max-width: 1450px) {
     
     .fixedTermInputBc{
          padding-top:0px;
          margin-top: -28px;
     }
     .beingTermInputBcContract{
          padding-top:0px;
          margin-top:10px;
     }
}
@media only screen and (min-width:992px) and (max-width: 1244px) {
     .bcAgreementSubTitle{
          
     }
     .fixedTermInputBc{
          padding-top:0px;
          margin-top: -28px;
     }
     .beingTermInputBcContract{
          padding-top:0px;
          margin-top:10px;
     }
}
@media only screen and (min-width:653px) and (max-width: 991px) {
     .demoDataBcContract{
          margin-bottom:0px ;
     }
     .bcAgreementAttachment{
          margin-left:15px;
     }
     .bcAgreementSubTitle{
          margin-left:20px;
     }
     .step2CardContainerBCContract{
          margin-top: 0px;
          margin-bottom: 0px;
          margin-right: 30px;
          margin-left: 30px;
          font-weight: 600;
     }
     .rentalInfoSentenceBCContract2{
          width:80%;
     }
     .beingTermInputBcContract{
          padding-top:0px;
          margin-top:10px;
     }
     .addressForServiceBcApp{
          margin-top:20px;
          margin-bottom:5px;
     }
     .bcPeriodCheckBoxes{
          margin-top: 0px;
          margin-bottom: 5px;
          margin-left: 70px;
     }
     .fixedTermInputBc{
          padding-top:0px;
          margin-top: -28px;
     }
}
@media only screen and (min-width:600px) and (max-width: 654px) {
     .demoDataBcContract{
          margin-bottom:0px ;
     }
     .bcAgreementAttachment{
          margin-left:15px;
     }
     .bcAgreementSubTitle{
          margin-left:20px;
     }
     .bcAgreementSubTitle2{
          margin-left:0px;
     }
     .step2CardContainerBCContract{
          margin-top: 0px;
          margin-bottom: 0px;
          margin-right: 20px;
          margin-left: 20px;
     }
     .rentalInfoSentenceBCContract2{
          width:100%;
     }
     .fixedTermInputBc{
          padding-top:0px;
          margin-top: -28px;
          margin-left: 20px;
     }
     .bcPeriodCheckBoxes{
          margin-top: 0px;
          margin-bottom: 5px;
          margin-left: 70px;
     }
     .addressForServiceBcApp{
          margin-top:20px;
          margin-bottom:5px;
     }
     .beingTermInputBcContract{
          padding-top:0px;
          margin-top:-30px;
          margin-bottom:-10px;
     }
     .beingTermDateLabelBcContract{
          float: left;
     }

}
@media only screen and (min-width: 0px) and (max-width: 599px) {
     .demoDataBcContract{
          margin-bottom:0px ;
     }
     .bcAgreementSubTitle{
          margin-left:15px;
          font-size: 15px;
     }
     .bcAgreementSubTitle2{
          margin-left:0px;
          font-size: 15px;
     }
     .bcAgreementAttachment{
          margin-left:5px;
     }
     .step2CardContainerBCContract{
          margin-top: 0px;
          margin-bottom: 0px;
          margin-right: 20px;
          margin-left: 20px;
     }
     .step2CardBCContract{
          padding-left: 2%;
          padding-right: 2%;
     }
     .rentalInfoSentenceBCContract2{
          width:100%;
     }
     .rentalInfoSentenceBCContract0{
          width:100%;
     }
     .rentalInfoInputBCContract{
          margin-top: -20px;
          width:100%;
     }
     .tenancyEndReasonDiv{
          margin-left: 20px; 
     }
     .fixedTermInputBc{
          padding-top:0px;
          margin-top: -28px;
          margin-left: 20px;
     }
     .bcPeriodCheckBoxes{
          margin-top: 0px;
          margin-bottom: 5px;
          margin-left: 50px;
     }
     .addressForServiceBcApp{
          margin-top:20px;
          margin-bottom:5px;
     }
     .stepFormNavTitle{
          height: 80px;
     }

     .beingTermInputBcContract{
          padding-top:0px;
          margin-top:-30px;
          margin-bottom:-10px;
     }
     .beingTermDateLabelBcContract{
          float: left;
     }
     .tenancyEndReasonDivInput{
          margin-top: -10px;
     }
}
@media only screen and (min-width: 0px) and (max-width: 450px) {
     .step2CardBCContract{
          padding-left: 5%;
          padding-right: 5%;
     }
}