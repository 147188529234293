.addApp1 {
  /* padding-top:5px; */
}
.roundNumberBadge {
  border-radius: 20px;
  border: solid 3px #fff;
  background-color: #ff4b2b;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
}
.applicationTenantBudge {
  width: 100%;
  cursor: pointer;
  /* background-color: rgb(231, 20, 20); */
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  /* border-radius: 10px; */
}
.applicationTenantBudge:hover {
  background-color: #e7e4e479 !important;
  color: #fff;
}
.contractCard1 {
  background-color: #fff;
  /* border-top: .00001rem solid #f7ada0; */
  /* border-bottom: .00001rem solid #f7ada0; */
  /* border-right: .3rem solid #f7ada0; */
  /* border-left: .3rem solid #f7ada0; */
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  /* box-shadow:
         inset 0 -3em 3em rgba(206, 223, 240, 0.131),
          0 0  0 2px rgb(255,255,255),
          0.3em 0.3em 1em rgba(192, 187, 187, 0.3); */
  /* margin-top: -20px;       */
  /* height:80px; */
  margin-top: 15px;
  padding-top: 5px;
  cursor: pointer;
}
.bottomRad {
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}
.contractImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 7px;
  /* margin-top: 5px; */
}

.zoom {
  animation: heart 1s infinite alternate;
}
.appNotification {
  background-color: #ff7d6a;
  margin-top: 20px;
}
@keyframes heart {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.zoom1 {
  animation: heart1 2.5s infinite alternate;
}

@keyframes heart1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.contractCardDetail {
  /* margin-top: -10px; */
  background-color: rgb(248, 244, 244);
  /* border-right: .3rem solid #f7ada0; */
  /* border-left: .3rem solid #f7ada0; */
  border-color: #fcc1b7;
  padding-right: 50px;
  padding-left: 50px;
}
.tenantDetailBox {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  /* background: -webkit-linear-gradient(to right, #cfcccc, #ffffff);
    background: linear-gradient(to right, #dcdddd, #ffffff); */
  background-color: #ffffff;
}
.appTenantImage {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -7px;
  border-radius: 19px;
  height: 50px;
}

.appStatus1 {
  text-align: "center";
  padding: 9px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}
.vert-move-x {
  -webkit-animation: moverX 2s infinite alternate;
  animation: moverX 2s infinite alternate;
}
.tenantShow {
  font-size: 14px;
}
@-webkit-keyframes moverX {
  0% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}
@keyframes moverX {
  0% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}
.contractCardDetailPaddingBottom {
  /* border-top: .00001rem solid #f7ddd8; */
  /* border-bottom: .2rem solid #f7ada0; */
  background-color: #ffffff;
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;

  margin-bottom: 3%;
  padding-bottom: 20px;
  padding-top: 10px;
}
.contractCardDetailPaddingTop {
  border-top: 0.00001rem solid #f7ada0;
  background-color: #ffffff;
  border-top: 0.2rem solid #dee0e2d9;
  box-shadow: inset 0 -3em 3em rgba(206, 223, 240, 0.131),
    0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(192, 187, 187, 0.3);
}
.contractMainCard {
  background-color: #ffffff;
  /* border-top-left-radius:50px ; */
  /* border-bottom-left-radius:50px ; */
  border-top-left-radius: 0.5rem;
  /* margin-top: -20px; */
  /* height:60px; */
}

.showMenu {
  height: auto;
  overflow: hidden;
  transition: height ease-out;
}
@keyframes height {
  0% {
    height: 0;
  }

  100% {
    height: auto;
  }
}
.hideMenu {
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}
.contractName {
  font-size: 21px;
  font-family: "Montserrat", sans-serif;
}
.unitName {
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
}
.status {
  font-family: "Montserrat", sans-serif;
  font-size: 5px;
}
.contractButton {
  font-family: "Montserrat", sans-serif;
  padding-left: 8%;
  padding-right: 8%;
}
.successText {
  background-color: #d5f5e3;
  color: #7dcea0;
}
.primaryText {
  background-color: #367199;
}
.primaryText:hover {
  background-color: #6999b9;
  cursor: pointer;
}
.warningText {
  background-color: #fdebd0;
  color: #e59866;
}
.dangerText {
  background-color: #fdedec;
  color: #ec7063;
}
.infoText {
  background-color: #bb8fce;
}
.secoundaryText {
  background-color: #bdc3c7;
}
.editButton {
}
.editButton:hover {
  background-color: #9b59b6;
  cursor: pointer;
}
.detailsText {
  font-size: 16px;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.detailsText1 {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  font-weight: 400;
}
.listApp {
  padding-top: 30px;
  padding-right: 1.5%;
  padding-left: 1.5%;
}
.bodyApplication {
  height: 500px;
  overflow: scroll;
}
.applicationCard {
  background-color: #fff;
  border-radius: 15px;
  margin-right: 5px !important;
  margin-left: 5px !important;
  height: 100%;

}
.applicationCardDark {
  background-color: rgb(100, 100, 100);
  border-radius: 15px;
  margin-right: 5px !important;
  margin-left: 5px !important;
  height: 100%;
}
.tenantListAlign {
  min-height: 155px;
}
.bodyApplication::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.bodyApplication::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.bodyApplication::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.bodyApplication::-webkit-scrollbar-thumb:hover {
  background: #918c8b;
}
